@import './styles/font-fase.css';
@import './styles/scroll.css';

* {
   margin: 0;
   padding: 0;
   list-style-type: none;
   font-style: normal;
   text-decoration: none;
   box-sizing: border-box;
   font-family: 'Exo2-Bold';
   outline: none;
}

*::before,
*::after {
   box-sizing: border-box;
}

a,
a:link,
a:visited {
   font-family: 'Exo2-Regular';
   text-decoration: none;
   transition: all 0.5s;
}

a:hover {
   text-decoration: none;
}

.App {
   min-height: 100dvh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.x6-node * {
   min-height: 60px !important;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
}

.x6-node [magnet='true'] {
   height: 9px !important;
   width: 50px !important;
}
ul,
ul li {
   list-style: none;
}

body {
   min-height: 100vh;
   font-family: 'Exo2-Regular';
   -webkit-touch-callout: none; /* Отключает контекстное меню iOS */
   -webkit-user-select: none; /* Отключает выделение текста на iOS */
   -webkit-tap-highlight-color: rgba(
      0,
      0,
      0,
      0
   ); /* Отключает подсветку при нажатии на iOS */
   -webkit-touch-callout: none; /* Отключает контекстное меню iOS */
   -webkit-user-drag: none; /* Отключает перетаскивание элемента */
   -webkit-user-select: none; /* Отключает выделение текста */
   -khtml-user-select: none; /* Отключает выделение текста в старых версиях Safari */
   -moz-user-select: none; /* Отключает выделение текста в Firefox */
   -ms-user-select: none; /* Отключает выделение текста в Internet Explorer */
   user-select: none;
}
