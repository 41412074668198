::-webkit-scrollbar {
   width: 8px; /* Ширина вертикального скроллбара */
   height: 8px; /* Ширина горизонтального скроллбара */
}

::-webkit-scrollbar-thumb {
   background-color: #888; /* Цвет ползунка */
   border-radius: 4px; /* Закругление углов ползунка */
}

::-webkit-scrollbar-thumb:hover {
   background-color: #555; /* Цвет ползунка при ховере */
}

::-webkit-scrollbar-track {
   background-color: #f1f1f1; /* Цвет дорожки (трек) */
}
